.secondCardkdtImg {
  width: 245px;
  height: 100%;
}
.HomeKlassImg {
  width: 100%;
}
.HoverImgScale {
  img {
    transition: all 0.3s;
    transform: scale(1, 1);
  }
  &:hover img {
    transition: all 0.3s;
    transform: scale(1.1, 1.1);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .HomeKlassImg {
    width: 140px;
    height: 140px;
  }
  .cardWrap {
    width: calc(50% - 12px);
    margin-left: 16px;
    &:not(:nth-child(1), :nth-child(2), :nth-child(3)) {
      margin-left: 24px;
    }
  }
}

@media all and (max-width: 767px) {
  .secondCardkdtImg {
    width: 100%;
    height: auto;
  }
  .HomeKlassImg {
    width: 124px;
    height: 124px;
  }
  .cardWrap {
    max-width: calc(100% - 70px);
  }
  .schoolTitle {
    margin-top: 6px;
    margin-bottom: 4px;
  }
}
